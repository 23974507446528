import axios from 'axios';


// process get params like post
const prepareGetUrl = (url, params) => {
    var getUrl = url;

    for (var k in params) {
        if (params.hasOwnProperty(k) && params[k] !== undefined) {
            getUrl += "/" + params[k];
        }
    }

    return getUrl
}

export const getSensorDataNow = ({ timestamp, sensor_id, update_interval }) => {

    return dispatch => {
        dispatch({ type: 'SENSORS_LOADING' });
        if (timestamp === null || timestamp === undefined) {
            axios.get(prepareGetUrl('api/sensors/now', { sensor_id }))
                .then(res => {
                    if (update_interval !== null && update_interval !== undefined && update_interval === true) {
                        dispatch({ type: 'ADD_DATA_NOW_UPDATE', data: { sensor_data: res.data.sensors, time_interval: res.data.time_interval, state: 'now' } });
                    }
                    else {
                        dispatch({ type: 'ADD_DATA_NOW', data: { sensor_data: res.data.sensors, state: 'now' } });
                    }
                })
                .catch(err => {
                    dispatch({ type: 'ADD_DATA_ERROR', err });
                })
        } else {
            axios.get(prepareGetUrl('api/sensors', { timestamp, sensor_id }))
                .then(res => {
                    let sensorExistAlarm = false;

                    if (update_interval !== null && update_interval !== undefined && update_interval === true) {
                        dispatch({ type: 'ADD_DATA_NOW_UPDATE', data: { sensor_data: res.data.sensors, time_interval: res.data.time_interval, state: 'playback' } });
                    }
                    else {

                        const FIFTEEN_MIN = 900000; // 15 * 60 * 1000
                        const now = Date.now();
                        
                        let old_alarms = [] 

                        // check if alarms need update (REAL TIME ONLY)
                        if (res.data.sensors && (now - (timestamp * 1000)) < FIFTEEN_MIN) {
                            old_alarms = localStorage.getItem('alarms')
                            old_alarms = JSON.parse(old_alarms)

                            if( !old_alarms){
                                old_alarms = []
                            }

                            res.data.sensors.forEach((sensor) => {

                                const sensor_ts = Math.round(new Date(sensor.Date).getTime() / 1000)

                                // alarm was triggered
                                if (sensor['alarm_state'] === 1) {
                                    sensorExistAlarm = true // alarm found

                                    let alarm_already_exists = false
                                    old_alarms.forEach((old_value) => {
                                        if(old_value.location_id === sensor.location_id){

                                            alarm_already_exists = true

                                            // need to be replaced
                                            if(old_value.ts !== sensor_ts){
                                                old_value = {
                                                    location_id: sensor.location_id,
                                                    ts: sensor_ts,
                                                    closed: false
                                                }
                                            }
                                        }

                                    })

                                    if(alarm_already_exists === false){
                                        if(old_alarms) {
                                            old_alarms.push({
                                                location_id: sensor.location_id,
                                                ts: sensor_ts,
                                                closed: false
                                            })
                                        }
                                        else {
                                            old_alarms = []
                                        }
                                    }
                                }

                            })

                            // sort by ts
                            old_alarms.sort((a,b) => {
                                return b.ts - a.ts
                            })

                            localStorage.setItem('alarms', JSON.stringify(old_alarms))
                        }
                        else {
                            res.data.sensors.forEach((sensor) => {
                                // alarm was triggered
                                if (sensor['alarm_state'] === 1) {
                                    sensorExistAlarm = true // alarm found
                                }
                            })
                        }
                   
                        dispatch({ 
                            type: 'ADD_DATA_NOW', 
                            data: { 
                                sensor_data: res.data.sensors, 
                                sensorOldAlarms: old_alarms, 
                                sensorExistAlarm: sensorExistAlarm, 
                                state: 'playback' 
                            } 
                        });
                    }
                })
                .catch(err => {
                    dispatch({ type: 'ADD_DATA_ERROR', err });
                })
        }

    };
};

export const getSensorDataInterval = ({ timestamp_from, timestamp_to, sensor_id }) => {

    return dispatch => {
        dispatch({ type: 'SENSORS_LOADING' });

        axios.get(prepareGetUrl('api/sensors/interval', { timestamp_from, timestamp_to, sensor_id }))
            .then(res => {

                let max = 0;
                res.data.sensors.forEach((sensor) => {
                    if (sensor.TSP > max) {
                        max = sensor.TSP
                    }
                })

                res.data.max = max + 10

                dispatch({ type: 'ADD_DATA_INTERVAL', data: res.data });
            })
            .catch(err => {
                dispatch({ type: 'ADD_DATA_ERROR', err });
            }
            );
    };
};


export const getSensorLocations = (timestamp) => {
    return dispatch => {
        dispatch({ type: 'SENSORS_LOADING' });

        axios.get(prepareGetUrl('api/sensors/locations', {timestamp}))
            .then(res => {
                dispatch({ type: 'ADD_LOCATIONS', data: res.data });
            })
            .catch(err => {
                dispatch({ type: 'ADD_DATA_ERROR', err });
            }
            );
    };
};

export const saveSensorLocations = (thresholds) => {

    return dispatch => {
        dispatch({ type: 'SENSORS_LOADING' });

        axios.post('api/sensors/locations',{thresholds:thresholds})
            .then(res => {
                dispatch({ type: 'ADD_LOCATIONS', data: res.data });

            })
            .catch(err => {
                dispatch({ type: 'ADD_DATA_ERROR', err });
            }
        );
    };
};



export const setTimestamp = ({ timestamp, cloudTimestamp, nextTimestamp }) => {
    return dispatch => {
        dispatch({ type: 'SET_TIMESTAMP', timestamp: timestamp, cloudTimestamp: cloudTimestamp, nextTimestamp: nextTimestamp });
    }
};

export const setDisplaySpeed = ({ sensorDisplayChoosed }) => {
    return dispatch => {
        dispatch({ type: 'SET_DISPLAY_SPEED', sensorDisplayChoosed });
    }
};

export const setFromAlarm = ({ sensorFromAlarm }) => {
    return dispatch => {
        dispatch({ type: 'SET_FROM_ALARM', sensorFromAlarm });
    }
};

export const closeOldAlarm = ( location_id ) => {

    let old_alarms = localStorage.getItem('alarms')
    old_alarms = JSON.parse(old_alarms)

    if( !old_alarms){
        old_alarms = []
    }

    old_alarms.forEach((old_alarm) => {
        if(old_alarm.location_id === location_id || location_id === 'all'){
            old_alarm.closed = true
        }
    })

    localStorage.setItem('alarms', JSON.stringify(old_alarms))

    return dispatch => {
        dispatch({ type: 'CLOSE_OLD_ALARM', old_alarms });
    }
}

export const resetOldAlarm = () => {
    localStorage.removeItem('alarms')

    return dispatch => {
        dispatch({ type: 'RESET_OLD_ALARM' });
    }
}

export const resetSensorData = () => {
    return dispatch => {
        dispatch({ type: 'RESET_SENSOR_DATA' });
    }
};


export const saveChartScale = (chart_scale=90) => {

    return dispatch => {
        dispatch({ type: 'SENSORS_LOADING' });

        axios.post('/api/sensors/locations/save_scale',{chart_scale})
            .then(res => {
                dispatch({ type: 'SET_CHART_SCALE', data: res.data });

            })
            .catch(err => {
                dispatch({ type: 'ADD_DATA_ERROR', err });
            }
        );
    };
};