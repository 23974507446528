const initState = {
    alarmLoading: false,
    alarmError: null,
    alarmData: null,
    alarmUncheckedData : null
}

const alarmReducer = (state = initState, action) => {

    switch(action.type){
        case 'ADD_ALARM_UNCHECKED_DATA':
            return {
                ...state,
                alarmUncheckedData: action.data,
                alarmLoading: false,
            }
        case 'ADD_ALARM_UNCHECKED_DATA_ERROR':
            return {
                ...state,
                alarmError: (action.err.response !== undefined && action.err.response.data !== undefined ? action.err.response.data : "Internal Error"),
                alarmUncheckedData: null,
                alarmLoading: false,
            }
        case 'ADD_ALARM_DATA':
            return {
                ...state,
                alarmData: action.data,
                alarmLoading: false,
            }
        case 'ADD_ALARM_DATA_ERROR':
            return {
                ...state,
                alarmError: (action.err.response !== undefined && action.err.response.data !== undefined ? action.err.response.data : "Internal Error"),
                alarmData: null,
                alarmLoading: false,
            }
        case 'RESET_ALARM_DATA':
            return {
                alarmLoading: false,
                alarmError: null,
                alarmData: null
            }
        case 'ALARM_LOADING':
            return {
                ...state,
                alarmLoading: true,
            }
        default: 
            return state;
    }
    
}

export default alarmReducer