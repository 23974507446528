import React, { Component } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';

import { getSensorLocations, saveSensorLocations } from '../../store/actions/sensorActions'

class AdminAlarmSettings extends Component {
    constructor() {
        super()
        this.state = {}
    }

    componentDidMount() {
        this.props.getSensorLocations(Math.round(new Date().getTime() / 1000))
    }

    handleChange = e => {

        if (e.target.value.length === 0) {
            this.setState({
                [e.target.name]: ''
            })
        }
        else if (!isNaN(Number(e.target.value))) {
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    showMessage() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='shadow rounded p-5 bg-light text-center'>
                        <h5> Alarm settings changed successfully </h5>
                        <button className='btn btn-info mt-2 mx-1' onClick={() => {
                            this.props.history.goBack()
                            onClose()
                        }}> Ok </button>
                    </div>
                )
            }
        })
    };

    saveThresholds() {
        let mystate = this.state
        let newstate = {}

        if (this.props.sensorLocations) {
            this.props.sensorLocations.forEach((location) => {
                if(!newstate[location.id]){
                    newstate[location.id] = {}
                }

                if (!([location.id +"_pm10-pm25"] in mystate)) {
                    newstate[location.id]["alarm_pm_trigger"] = location.alarm_pm_trigger
                }
                else{
                    newstate[location.id]["alarm_pm_trigger"] = mystate[location.id +"_pm10-pm25"]
                }

                if (!([location.id  + "_wd-min"] in mystate)) {
                    newstate[location.id]["min_wind_direction_trigger"] = location.min_wind_direction_trigger
                }
                else{
                    newstate[location.id]["min_wind_direction_trigger"] = mystate[location.id +"_wd-min"]
                }

                if (!([location.id + "_wd-max"] in mystate)) {
                    newstate[location.id]["max_wind_direction_trigger"] = location.max_wind_direction_trigger
                }
                else{
                    newstate[location.id]["max_wind_direction_trigger"] = mystate[location.id +"_wd-max"]
                }

                if (!([location.id + "_ws-min"] in mystate)) {
                    newstate[location.id]["min_wind_speed_trigger"] = location.min_wind_speed_trigger
                }
                else{
                    newstate[location.id]["min_wind_speed_trigger"] = mystate[location.id +"_ws-min"]
                }
            })
        }

        // some function
        this.props.saveSensorLocations(newstate)
        this.showMessage()
    }

    drawLocationInputs() {
        if (this.props.sensorLocations) {
            return (
                this.props.sensorLocations.map((location) => {
                    console.log(location)
                    let location_pm10_pm25 = ""
                    let location_wd_min = ""
                    let location_wd_max = ""
                    let location_ws_min = ""

                    if (!this.state[location.id + "_pm10-pm25"] && this.state[location.id + "_pm10-pm25"] !== '') {
                        if (!location.alarm_pm_trigger) {
                            location_pm10_pm25 = ""
                        }
                        else {
                            location_pm10_pm25 = location.alarm_pm_trigger
                        }
                        
                    }
                    else {
                        location_pm10_pm25 = this.state[location.id + "_pm10-pm25"]
                    }

                    if (!this.state[location.id + "_wd-min"] && this.state[location.id + "_wd-min"] !== '') {
                        if (!location.min_wind_direction_trigger) {
                            location_wd_min = ""
                        }
                        else {
                            location_wd_min = location.min_wind_direction_trigger
                        }
                        
                    }
                    else {
                        location_wd_min = this.state[location.id + "_wd-min"]
                    }

                    if (!this.state[location.id + "_wd-max"] && this.state[location.id + "_wd-max"] !== '') {
                        if (!location.max_wind_direction_trigger) {
                            location_wd_max = ""
                        }
                        else {
                            location_wd_max = location.max_wind_direction_trigger
                        }
                        
                    }
                    else {
                        location_wd_max = this.state[location.id + "_wd-max"]
                    }

                    if (!this.state[location.id + "_ws-min"] && this.state[location.id + "_ws-min"] !== '') {
                        if (!location.min_wind_speed_trigger) {
                            location_ws_min = ""
                        }
                        else {
                            location_ws_min = location.min_wind_speed_trigger
                        }
                        
                    }
                    else {
                        location_ws_min = this.state[location.id + "_ws-min"]
                    }                    

                    return (           
                        <tr key={location.id}>
                            <td style={{maxWidth: "320px"}}> <label> {location.LOC} </label> </td>
                            <td> <input name={location.id + "_pm10-pm25"} type="text" className="form-control" onChange={this.handleChange || ''} value={location_pm10_pm25} /> </td>
                            <td> <input name={location.id +"_wd-min"} type="text" className="form-control" onChange={this.handleChange || ''} value={location_wd_min} /> </td>
                            <td> <input name={location.id +"_wd-max"} type="text" className="form-control" onChange={this.handleChange || ''} value={location_wd_max} /> </td>
                            <td> <input name={location.id +"_ws-min"} type="text" className="form-control" onChange={this.handleChange || ''} value={location_ws_min} /> </td>
                        </tr>     
                    )
                })
            )
        }
        else {
            return (
                <tr><td colSpan={5}> Loading locations...</td></tr>
            )
        }

    }

    render() {
        return (
            <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-xl-6 offset-xl-3 my-5 shadow px-5 py-4 bg-white rounded">
                <div className="col-12 text-center">
                    <h2> Alarm Settings</h2>
                </div>
                <div className="col-12 my-3">
                    Configure the threshold values to trigger the alarms
                </div>
                <div className="col-12">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th> Location </th>
                                <th> PM10 - PM2.5 </th>
                                <th> Min WD </th>
                                <th> Max WD </th>
                                <th> Min WS </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.drawLocationInputs()}
                        </tbody>
                    </table>
                </div>
                <div className="col-12 text-right mt-3 pr-4">
                    { this.props.user.role.name === 'SuperAdmin' ? <button className="btn btn-primary" type="button" onClick={() => this.saveThresholds()}> Save changes </button> : null}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        sensorLocations: state.sensor.sensorLocations,
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveSensorLocations: (params) => dispatch(saveSensorLocations(params)),
        getSensorLocations: (params) => dispatch(getSensorLocations(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAlarmSettings);
