import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyleSheet, pdf, Document, View, Page, Text } from '@react-pdf/renderer'

const styles = StyleSheet.create({
	page: { flexDirection: 'column', alignItems: 'center', fontSize: 9, fontFamily: 'Helvetica', color: 'black' },
	title: { fontSize: 12, fontFamily: 'Helvetica-Bold', color: 'black', marginTop: 30, marginBottom: 20 },
	line: { flexDirection: 'row', marginBottom: 5 },
});

class Export extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: new Date(),
			endDate: new Date(),
			startDateString: new Date().toISOString().substring(0, 10),
			endDateString: new Date().toISOString().substring(0, 10),
			selectedOption: 'excel',
			selectedTableOption: 'sensors',
			loading: false
		};

		this.handleStartChange = this.handleStartChange.bind(this);
		this.handleEndChange = this.handleEndChange.bind(this);
	}

	handleStartChange(date) {
		if (date instanceof Date && date !== null) {

			this.setState({
				startDate: date
			});
			this.setState({
				startDateString: date.toISOString().substring(0, 10)
			});
		}
	}

	handleEndChange(date) {
		if (date instanceof Date && date !== null) {
			this.setState({
				endDate: date
			});
			this.setState({
				endDateString: date.toISOString().substring(0, 10)
			});
		}
	}

	handleOptionChange = (changeEvent) => {
		this.setState({
			[changeEvent.target.name]: changeEvent.target.value
		});
	}

	downloadFileRequest = (start, end, format) => {
		if (this.state.selectedTableOption === 'sensors') {
			return axios.get('api/report/sensors/' + start + '/' + end + '/' + format, {
				responseType: 'blob'
			});
		}
		else if (this.state.selectedTableOption === 'meteo') {
			return axios.get('api/report/weather/' + start + '/' + end + '/' + format, {
				responseType: 'blob'
			});
		}
		else if (this.state.selectedTableOption === 'alarms') {
			return axios.get('api/report/alarms/' + start + '/' + end + '/' + format, {
				responseType: 'blob'
			});
		}
		else if (this.state.selectedTableOption === 'rainfall') {

			return axios.get('api/report/rainfall/' + start + '/' + end, {
				responseType: 'json'
			});

		}
		
	}

	downloadReport = () => async () => {
		let extension = '';
		this.setState({ loading: true })

		if (this.state.startDateString !== undefined && this.state.startDateString.length === 10 && this.state.endDateString !== undefined && this.state.endDateString.length === 10) {
			try {
				if (this.state.selectedTableOption === 'sensors' || this.state.selectedTableOption === 'meteo' || this.state.selectedTableOption === 'alarms') {
					const { data } = await this.downloadFileRequest(this.state.startDateString, this.state.endDateString, this.state.selectedOption);

					if (this.state.selectedOption === 'excel') {
						extension = 'xlsx'
					} else {
						extension = 'csv'
					}

					if (this.state.selectedTableOption === 'sensors') {
						await FileSaver.saveAs(data, 'dust_report_from_' + this.state.startDateString + '_to_' + this.state.endDateString + '.' + extension);
					}
					else if (this.state.selectedTableOption === 'meteo') {
						await FileSaver.saveAs(data, 'meteo_report_from_' + this.state.startDateString + '_to_' + this.state.endDateString + '.' + extension);
					}
					else if (this.state.selectedTableOption === 'alarms') {
						await FileSaver.saveAs(data, 'alarm_report_from_' + this.state.startDateString + '_to_' + this.state.endDateString + '.' + extension);
					}
				}
				else if (this.state.selectedTableOption === 'rainfall') {
					const { data } = await this.downloadFileRequest(this.state.startDateString, this.state.endDateString, this.state.selectedOption);

					const blob = await pdf(
						<Document>
							<Page size="A4" style={styles.page}>
								<View style={styles.title}>
									<Text> FDM (Fugitive Dust Monitor) © pdj milieu en industrie, The Netherlands. pdj.nl.</Text>
								</View>
								<View style={{ flexDirection: 'row' }}>
									<View style={{ flexDirection: 'column', alignItems: 'flex-end', marginRight: 10 }}>
										<View style={styles.line}>
											<Text> Precipitation report generated by: </Text>
										</View>
										<View style={styles.line}>
											<Text> dd. </Text>
										</View>
										<View style={styles.line}>
											<Text> Startdate </Text>
										</View>
										<View style={styles.line}>
											<Text> Enddate </Text>
										</View>
										<View style={styles.line}>
											<Text> Total precipitation </Text>
										</View>
									</View>
									<View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
										<View style={styles.line}>
											<Text style={{ color: 'blue', textDecoration: 'underline' }}> {this.props.user.email} </Text>
										</View>
										<View style={styles.line}>
											<Text> {data.now} </Text>
										</View>
										<View style={styles.line}>
											<Text> {data.startDate} </Text>
										</View>
										<View style={styles.line}>
											<Text> {data.endDate} </Text>
										</View>
										<View style={styles.line}>
											<Text> {data.totalRain + " mm"} </Text>
										</View>
									</View>
								</View>
							</Page>
						</Document>
					).toBlob();

					await FileSaver.saveAs(blob, 'rainfall_report_from_' + this.state.startDateString + '_to_' + this.state.endDateString + '.pdf');
				}

			} catch (error) {
				alert('Something went wrong while downloading this file');
			}
		} else {
			alert('Please select the dates')
		}

		this.setState({ loading: false })
	}

	displayBtn() {

		if (this.state.selectedTableOption === 'meteo') {
			return (
				<input type="button" className="btn btn-primary ml-2" value="Display online" />
			)
		}

		return null;
	}

	getFileFormat() {

		if (this.state.selectedTableOption === 'rainfall') {
			return null
		}

		return (
			<>
				<span className="mr-2 font-weight-bold mt-4"> File format: </span>
				<div className="d-flex flex-row mt-2">
					<div className="d-flex align-items-center mr-2">
						<input type="radio" value="excel" name="selectedOption"
							checked={this.state.selectedOption === 'excel'}
							onChange={this.handleOptionChange} />
						<span className="ml-1">Excel</span>
					</div>
					<div className="d-flex align-items-center">
						<input type="radio" value="csv" name="selectedOption"
							checked={this.state.selectedOption === 'csv'}
							onChange={this.handleOptionChange} />
						<span className="ml-1">CSV</span>
					</div>
				</div>
			</>
		)
	}

	render() {
		return (
			<div className="m-4 d-flex justify-content-around">
				<div className="d-flex flex-column justify-content-center bg-light p-3 rounded ">

					<h3 className="text-center mb-0"> Export data </h3>

					<div className="d-flex flex-row flex-wrap justify-content-center my-4">
						<div className="d-flex flex-column mx-3">
							<p className="mb-1 font-weight-bold">Starting date: </p>
							<DatePicker
								dateFormat="yyyy/MM/dd"
								selected={this.state.startDate}
								onChange={this.handleStartChange}
							/>

							<p className="mb-1 font-weight-bold mt-2">Ending date:</p>
							<DatePicker
								dateFormat="yyyy/MM/dd"
								selected={this.state.endDate}
								onChange={this.handleEndChange}
							/>
						</div>
						<div className="d-flex flex-column mx-3">

							<span className="mr-2 font-weight-bold"> Data: </span>
							<div className="d-flex flex-row mt-2">
								<div className="d-flex align-items-center mr-2">
									<input type="radio" value="sensors" name="selectedTableOption"
										checked={this.state.selectedTableOption === 'sensors'}
										onChange={this.handleOptionChange} />
									<span className="ml-1">Sensors</span>
								</div>
								<div className="d-flex align-items-center mr-2">
									<input type="radio" value="meteo" name="selectedTableOption"
										checked={this.state.selectedTableOption === 'meteo'}
										onChange={this.handleOptionChange} />
									<span className="ml-1">Meteo</span>
								</div>
								<div className="d-flex align-items-center mr-2">
									<input type="radio" value="alarms" name="selectedTableOption"
										checked={this.state.selectedTableOption === 'alarms'}
										onChange={this.handleOptionChange} />
									<span className="ml-1">Alarms</span>
								</div>
								<div className="d-flex align-items-center">
									<input type="radio" value="rainfall" name="selectedTableOption"
										checked={this.state.selectedTableOption === 'rainfall'}
										onChange={this.handleOptionChange} />
									<span className="ml-1">Total rainfall</span>
								</div>
							</div>
							{this.getFileFormat()}
						</div>
					</div>

					<div className="d-flex flex-row mx-3">
						<button type="button" className="btn btn-primary" onClick={this.downloadReport()}>
							{this.state.loading === true ? <FontAwesomeIcon icon="spinner" spin /> : "Export to file"}
						</button>
						{/* { this.displayBtn() } */}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.user,
	}
}

export default connect(mapStateToProps, null)(Export);