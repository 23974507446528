import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../store/actions/authActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormInput from '../forms_helper/FormInput';
import { confirmAlert } from 'react-confirm-alert';

import { setTimestamp, getSensorDataNow, getSensorDataInterval, resetSensorData } from '../../store/actions/sensorActions';
import { getWeatherData } from '../../store/actions/weatherActions';

class Login extends Component {
    state = {
        'email': '',
        'password': ''
    }

    constructor() {
        super();
    
        this.emailInput = React.createRef();
    }

    componentDidMount() {
        this.emailInput.current.focus();
    }

    handleChange = (e) => { 
        this.setState({
            [e.target.id] : e.target.value
        }) 
    }

    handleSubmit = (e) => {
        e.preventDefault()
        this.props.login(this.state)
    }

    showMessage() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='shadow rounded p-5 bg-light text-center'>
						<h5> This is your first time login, we recommend to change your password </h5>
						<button className='btn btn-info mt-2 mx-1' onClick={ () => {
                            this.props.history.push('/user/changepassword')
                            onClose()
						}}> Go to change password </button>
					</div>
				)
			}
		})
	};

    componentDidUpdate() {
        if(this.props.loggedIn === true){
            
            const interval = {
                timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
                timestamp_to: Math.round(new Date().getTime()/1000),
            }

            this.props.resetSensorData()

            this.props.getSensorDataInterval(interval)
            this.props.getSensorDataNow({ timestamp: interval.timestamp_to })
            this.props.getWeatherData({ timestamp: interval.timestamp_to })                

            this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp:interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })

            if(this.props.user.first_login === true){
                this.showMessage()
            }
            else{
                this.props.history.push("/")
            }
        }
    }

    render() {
        console.log(this.props.authError)
        return (
            <div className="d-flex justify-content-center ">
                <div className="col-10 col-sm-8 col-md-6 col-xl-4 my-5 shadow p-3 mb-5 bg-white rounded">     
                
                    <h2 className="text-center">Login</h2>

                    <form onSubmit={ this.handleSubmit }>
                        <FormInput
                            id="email"
                            name="email"
                            type="text"
                            placeholder="E-mail"
                            className="form-control form-email p-3 text-center"
                            onChange = { this.handleChange }
                            disabled = { this.props.authLoading }
                            errorMessage = {this.props.authError}
                            ref={this.emailInput}
                        />
                        
                        <FormInput
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            className="form-control form-password p-3 text-center"
                            onChange = { this.handleChange }
                            disabled = { this.props.authLoading }
                        />

                        <div className="row">
                            <div className="col-12 text-center px-4 text-light">
                                {this.props.authError && this.props.authError.message ? (<p className='bg-danger mx-2 p-2'>{this.props.authError.message}</p>) : null }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-primary btn-sm text-uppercase px-5 mr-3 float-right" disabled={ this.props.authLoading }>
                                        { this.props.authLoading === true ? <FontAwesomeIcon icon="spinner" spin /> : "Login" }
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authLoading: state.auth.authLoading,
        authError: state.auth.authError,
        loggedIn: state.auth.loggedIn,
        user: state.auth.user,
        sensorUpdateInterval: state.sensor.sensorUpdateInterval,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSensorDataInterval : (params) => dispatch( getSensorDataInterval(params) ),
        getSensorDataNow : (params) => dispatch( getSensorDataNow(params) ),
        setTimestamp: (params) => dispatch(setTimestamp(params)),
        resetSensorData: () => dispatch( resetSensorData() ),
        login : (credentials) => dispatch( login(credentials) ),
        getWeatherData: (params) => dispatch(getWeatherData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);