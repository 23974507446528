import React, { Component } from 'react';
import { connect } from 'react-redux';

import MapDust from './MapDust';
import WindCompass from './WindCompass';
import FooterMenu from '../layout/FooterMenu';
import StackedChart from './StackedChart';
import { closeOldAlarm } from '../../store/actions/sensorActions'

class Dashboard extends Component {

    constructor(){
        super()
        
        this.state = {
            selected_chart: null,
            selected_chart_alert: false
        }

        this.clickMiniChart = this.clickMiniChart.bind(this)
        this.closeChart = this.closeChart.bind(this)
        this.drawSelectedChart = this.drawSelectedChart.bind(this)
    }

    clickMiniChart = (sensor_name) => {

        if (sensor_name !== this.state.selected_chart) {
            this.props.closeOldAlarm("all")
            
            this.setState({ 
                selected_chart: sensor_name,
                selected_chart_alert: false
            })
        }
        else {
            this.closeChart()
        }
    }

    closeChart = () => {
        this.props.closeOldAlarm(parseInt(this.state.selected_chart))
        this.setState({ selected_chart: null, selected_chart_alert:false })
    }

    drawMiniCharts = () => {
        const locations = this.props.sensorLocations ? this.props.sensorLocations : [];
        
        return (
            <div>
                {
                    locations.map((location) => {
                        return(
                            <div className={"bg-light rounded shadow " + (this.state.selected_chart === location.id ? "mini-chart-active" : "mini-chart")} onClick={() => this.clickMiniChart(location.id)} style={{
                                position: "absolute",
                                top: (this.props.height_ratio / location.coordinates.chart_top) + this.props.centerShift_y,
                                left: (this.props.width_ratio / location.coordinates.chart_left) + this.props.centerShift_x,
                                width: this.props.width_ratio / location.coordinates.chart_width,
                                height: this.props.height_ratio / location.coordinates.chart_height
                            }} key={location.id}>
                                <StackedChart location_id={location.id} miniDisplay={true} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    drawSelectedChart() {
        if (this.state.selected_chart !== null) {
            return (
                <div style={{
                    position: "absolute",
                    top: (window.innerHeight - 54 - 56) / 3,
                    right: 50,
                    width: 500,
                    height: 300
                }}>
                    <StackedChart tooltip_x={0} location_id={this.state.selected_chart} alert={this.state.selected_chart_alert} closeChart={this.closeChart} />
                </div>
            )
        }
        else {
            return null
        }
    }

    checkAlarm(){
        const FIFTEEN_MIN = 900000; // 15 * 60 * 1000
        const now = Date.now();
        
        // check if alarms need update (REAL TIME ONLY)
        if (now - (this.props.sensorCloudTimestamp * 1000) < FIFTEEN_MIN) {
            const open_alarms = this.props.sensorOldAlarms.filter((old_alarm) => {
                return old_alarm.closed === false
            })
    
            if(open_alarms && open_alarms.length > 0){
                
                // alarm chart need update
                if(this.state.selected_chart !== open_alarms[0].location_id){
                    this.setState({
                        selected_chart: open_alarms[0].location_id,
                        selected_chart_alert: true
                    })
                }
            }
        }
        else {
            const {selected_chart, selected_chart_alert} = this.state
            
            if(selected_chart !== null && selected_chart_alert !== false){
                this.setState({
                    selected_chart: null,
                    selected_chart_alert: false
                })
            }
        }
       
    }

    render() {

        const miniCharts = this.props.width_ratio !== undefined && this.props.width_ratio !== null ? this.drawMiniCharts() : null
        this.checkAlarm()

        return (
            <div className="container-fluid p-0">
                <MapDust />
                <WindCompass />

                {this.props.sensorLocations ? miniCharts : null}
                {this.drawSelectedChart()}

                <FooterMenu />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        sensorOldAlarms: state.sensor.sensorOldAlarms,
        sensorCloudTimestamp: state.sensor.sensorCloudTimestamp,
        width_ratio: state.map.width_ratio,
        height_ratio: state.map.height_ratio,
        centerShift_x: state.map.centerShift_x,
        centerShift_y: state.map.centerShift_y,
        sensorLocations: state.sensor.sensorLocations,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeOldAlarm : (params) => dispatch( closeOldAlarm(params) ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
