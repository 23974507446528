import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Config } from '../../configs/config';

class MeteoChart extends Component {


    render(){       
        return (
			<div className="row rounded p-3 bg-light" style={{height: "368px", fontSize: "0.92em"}}>
                <div className="col-12 text-center">
                    <h4> Meteo info</h4>
                    {this.props.weatherData && this.props.weatherData.UserInfo && !this.props.weatherData.UserInfo.toLowerCase().includes(Config.client) ? 
                        <p className='text-danger mb-0'> Using fallback meteostation</p> : null
                    }
                </div>
                <div className="col-12 d-flex flex-row flex-wrap justify-content-around">
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column text-right pr-2">
                            <b> Average wind direction: </b>
                            <b> Average wind speed:  </b>
                            <b> Windgust direction: </b>
                            <b> Gust speed:  </b>
                            <b> Air pressure:  </b>
                            <b> Relative humidity:  </b>
                            <b> Temperature:  </b>
                        </div>
                        <div className="d-flex flex-column">
                            <span> {this.props.weatherData ? this.props.weatherData.AvgWD : ""} º</span>
                            <span> {this.props.weatherData ? this.props.weatherData.AvgWS / 10 : ""} m/s</span>
                            <span> {this.props.weatherData ? this.props.weatherData.WGD : ""} º</span>
                            <span> {this.props.weatherData ? this.props.weatherData.GS : ""} m/s</span>
                            <span> {this.props.weatherData ? this.props.weatherData.AirPress : ""} hPa</span>
                            <span> {this.props.weatherData ? this.props.weatherData.RelHum : ""} %</span>
                            <span> {this.props.weatherData ? this.props.weatherData.Temp : ""} ºC</span>
                        </div>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column text-right pr-2">
                            <b> Dewpoint:  </b>
                            <b> Absolute humidity:  </b>
                            <b> Rain Intensity:   </b>
                            <b> Rain status: </b>
                            {this.props.weatherData && this.props.weatherData.Temp < 10 ? (<b>Windchill:</b>) : ""}   
                            {this.props.weatherData && this.props.weatherData.Temp > 24 ? (<b>Heat Index::</b>) : ""}
                            <b> Air density:   </b>
                            <b> Wet bulb temperature:  </b>
                        </div>
                        <div className="d-flex flex-column">
                            <span> {this.props.weatherData ? this.props.weatherData.DewPoint : ""} ºC</span>
                            <span> {this.props.weatherData ? this.props.weatherData.AbsHum : ""} g/m3</span>
                            <span> {this.props.weatherData ? this.props.weatherData.RainInt : ""} mm/hr</span>
                            <span> {this.props.weatherData ? this.props.weatherData.RainStatus : ""} </span>
                            {this.props.weatherData && this.props.weatherData.Temp < 10 ? ( <span> {this.props.weatherData.WindChill + " ºC"}</span>) : ""}
                            {this.props.weatherData && this.props.weatherData.Temp > 24 ? (<span> {this.props.weatherData.HeatIndex} </span>) : ""} 
                            <span> {this.props.weatherData ? this.props.weatherData.AirDens : ""} kg/m3</span>
                            <span> {this.props.weatherData ? this.props.weatherData.WetBulbTemp : ""} ºC</span>
                        </div>
                    </div>
                </div>
			</div>
		);
    }
}

const mapStateToProps = (state) => {
	return {
		weatherData: state.weather.weatherData,
		weatherError: state.weather.weatherError
	}
}

export default connect(mapStateToProps, null)(MeteoChart);