import React, { Component } from 'react';
import {
	ResponsiveContainer, Rectangle, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Surface, Symbols
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Windrose from 'windrose';


const CustomBar = (props) => {
	const {Alarm, fill} = props;
	let new_fill = fill

	// check if exists an alarm
	if(Alarm && Alarm === 1) {
		new_fill='red';
	}
  
	//use explicit fill here, or use the additional css class and make a css selector to update fill there
	return <Rectangle {...props} fill={new_fill} className='recharts-bar-rectangle' />
};

const CustomTooltip = ({ payload }) =>{

	if(!payload[0]){
		return (
			<></>
		)
	}
	
	return (
		<div className='border p-2 text-center' style={{backgroundColor: "white", fontSize: 13}}>
			<div className='d-flex flex-column'>
				<div className='pb-2'> { payload[0].payload.Date }</div>
				{
					payload.map((item) => {
						if (item.name === 'PM2.5') {
							item.value = (Number(item.value) + Number(item.payload['PM1'])).toPrecision(3);
						} else if (item.name === 'PM10') {
							item.value = (Number(item.value) + Number(item.payload['PM2.5']) + Number(item.payload['PM1'])).toPrecision(3);
						} else if (item.name === 'TSP') {
							item.value = (Number(item.value) + Number(item.payload['PM10']) + Number(item.payload['PM2.5']) + Number(item.payload['PM1'])).toPrecision(3)
						} else if (item.name === 'Avg Wind m/s') {
							item.value = (item.payload['Avg Wind m/s']);
						}else if (item.name === 'Avg Direction') {
							item.value = (item.payload['Avg Direction']);
						}
						return (
							<div className='pb-2' style={{color: item.color}}> { item.name }: { item.value }</div>
						)
					})
				}
			</div>
		</div>
	)
}

class StackedChart extends Component {

	filterData = (sensor) => {
    	return sensor.location_id === parseInt(this.props.location_id);
	}

	extractHoursMinutesFromDatetime = (datetime) => {
		return moment.tz(datetime, "Europe/Amsterdam").local().format("HH:mm")
	}

	converTimestampToDate = (timestamp) => {
		return (new Date(timestamp * 1000).getHours().toString() + ':' + (new Date(timestamp * 1000).getMinutes() < 10 ? '0' : '') + new Date(timestamp * 1000).getMinutes().toString())
	}

	getLocation(location_id){
		
		const locations = this.props.sensorLocations

		if(locations){
			for( let i = 0; i<locations.length; i++){
				if(parseInt(locations[i].id) === parseInt(location_id)){
					return locations[i]
				}
			}
		}

		return null
	}


	sensor_data = () => {
		if (this.props.sensorDataInterval !== null) {

			const temp = this.props.sensorDataInterval.filter(this.filterData);
			let data = []

			this.maxVal = this.getLocation(this.props.location_id).chart_scale
			this.props.sensorChartInterval.forEach( chart_timestamp => {
				
				const data_ts = temp.filter(function(sensor) {
					return sensor.Date_Chart === chart_timestamp;
				})

				if(data_ts.length > 0){
					data_ts.forEach( sensor => {

						data.push({
							'TSP': sensor.TSP.toFixed(2) - sensor.PM10.toFixed(2),
							'PM10': sensor.PM10.toFixed(2) - sensor.PM25.toFixed(2),
							'PM2.5': sensor.PM25.toFixed(2) - sensor.PM1.toFixed(2),
							'PM1': sensor.PM1.toFixed(2),
							'DateAxisX': this.converTimestampToDate(sensor.Date_Chart),
							'Date': this.extractHoursMinutesFromDatetime(sensor.Date),
							'Avg Wind m/s': (sensor.AvgWS / 10).toFixed(2),
							'LOC': sensor.LOC,
							'Batt': sensor.Batt,
							'Temp': sensor.Temp,
							'Alarm': sensor.alarm_state,
							'Avg Direction': Windrose.getPoint(sensor.AvgWD, { depth: 2 }).symbol
						})
					})
				}
				else{
					data.push(
						{
							'TSP': 0,
							'PM10': 0,
							'PM2.5': 0,
							'PM1': 0,
							'DateAxisX': this.converTimestampToDate(chart_timestamp),
							'Date': this.converTimestampToDate(chart_timestamp),
							'Avg Wind m/s': 0,
							'LOC': 0,
							'Batt': 0,
							'Temp': 0,
							'Avg Direction': ""
						}
					)
				}
			})			
		
			
			return data;
		}

	}


	getStackedChart = () => {
		const sensor_data = (this.sensor_data() !== undefined && this.sensor_data() !== null) ? this.sensor_data() : []
		const sensor_data_length = sensor_data.length - 1

		if (sensor_data_length > 0) {
			// const voltage =  sensor_data[sensor_data_length].Batt 
			// const temperature = sensor_data[sensor_data_length].Temp 
			if (this.props.miniDisplay === true) {
				return (
					<ResponsiveContainer width="100%">
						<BarChart
							data={sensor_data}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="DateAxisX" hide />
							<YAxis allowDataOverflow={true} type="number" domain={[0, Math.round(this.maxVal)]} hide />
							<Bar shape={CustomBar} dataKey="PM1" stackId="a" fill="#e55039" />
							<Bar shape={CustomBar} dataKey="PM2.5" stackId="a" fill="#f6b93b" />
							<Bar shape={CustomBar} dataKey="PM10" stackId="a" fill="#10ac84" />
							<Bar shape={CustomBar} dataKey="TSP" stackId="a" fill="#0652DD" />
						</BarChart>
					</ResponsiveContainer>
				)
			}
			else {
				const renderLegend = ({ payload }) => {
					return (
					  <ul>
						{
						  payload.map((entry, index) => {
							const { dataKey, color } = entry

							// remove non wanted legends
							if(dataKey !== 'Avg Wind m/s' && dataKey !== 'Avg Direction'){
								return (
									<span key={`item-${index}`} >
										<Surface  width={10} height={10} >
											<Symbols cx={ 2 } cy={ 2 } type="square" size={100} fill={color} /> 
										</Surface>
										<span className="mr-2">{dataKey}</span>
									</span>
								)
							}
							else {
								return null
							}
						  })
						}
					  </ul>
					);
				}
				
				return (					
					<div className={this.props.alert ? "row rounded p-3 box-glowing bg-light" : "row rounded p-3 bg-light"}>
						<div className="col-12 d-flex justify-content-between align-items-center">
                        	<h4 style={{marginLeft: 150}}> {this.props.sensorLocations  && this.props.location_id && this.getLocation(this.props.location_id) ? this.getLocation(this.props.location_id).LOC : null} </h4>
                        	{ this.props.closeChart ? <FontAwesomeIcon className="btn-times-close" icon="times" onClick={this.props.closeChart} title="Close"/> : null }
                    	</div>
						<div className="col-12">
							<div className="row">
								<div className="col-12 text-center" style={{ height: 300 }}>
									<ResponsiveContainer width="100%">
										<BarChart
											data={sensor_data}
											margin={{
												top: 0, right: 0, left: 100, bottom: 0,
											}}
										>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="DateAxisX" interval={7} />
											<YAxis allowDataOverflow="true" type="number" domain={[0, Math.round(this.maxVal)]} />
											<Tooltip content={<CustomTooltip {...this.props} />} position={this.props.tooltip_x!==undefined? {x:this.props.tooltip_x,y:0}:'auto'}/>
 											<Legend content={renderLegend} />											
											<Bar shape={CustomBar} dataKey="PM1" stackId="a" fill="#e55039" />
											<Bar shape={CustomBar} dataKey="PM2.5" stackId="a" fill="#f6b93b" />
											<Bar shape={CustomBar} dataKey="PM10" stackId="a" fill="#10ac84" />
											<Bar shape={CustomBar} dataKey="TSP" stackId="a" fill="#0652DD" />
											<Bar shape={CustomBar} dataKey="Avg Wind m/s" stackId="a" visibility="hidden" />
											<Bar shape={CustomBar} dataKey="Avg Direction" stackId="a" visibility="hidden" fill="#22a6b3" />
										</BarChart>
									</ResponsiveContainer>
								</div>
							</div>
						</div>
					</div>
				)
			}

		} else {
			return (<div className="text-center">
				No Data Available
      </div>)

		}
	}


	render() {
		return this.getStackedChart()
	}
}

const mapStateToProps = (state) => {
	return {
		sensorDataInterval: state.sensor.sensorDataInterval,
		sensorChartInterval: state.sensor.sensorChartInterval,
		sensorChartMax: state.sensor.sensorChartMax,
		sensorLocations: state.sensor.sensorLocations,
		sensorError: state.sensor.sensorError
	}
}

export default connect(mapStateToProps, null)(StackedChart);