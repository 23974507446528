import React, { Component } from 'react';
import axios from 'axios';
import GenericForm from '../forms_helper/GenericForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert'; 

class ChangePassword extends Component {

    state = {
        id: null,
        password: '',
        password_confirmation: '',
        loading: false
    }


    componentDidMount() {
        this.setState({
            id: this.props.match.params.id
        })
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    showMessage() {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='shadow rounded p-5 bg-light text-center'>
						<h5> Password changed successfully </h5>
						<button className='btn btn-info mt-2 mx-1' onClick={ onClose }> Ok </button>
					</div>
				)
			}
		})
	};

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ loading: true })

        var password = {
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        };

        if (this.state.id !== null) {
            password['id'] = this.state.id
        }

        axios.post('api/users/changepassword', { ...password })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                })
                this.showMessage()
                this.props.history.push("/admin")
            })
            .catch(err => {

                this.setState({
                    error: err.response.data,
                    loading: false
                })
            })
    }

    render() {
        return (
            <div className="row">
                <div className="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4 my-5 shadow px-5 py-4 bg-white rounded">
                    <h2 className="text-center pb-3">Change Password</h2>
                    <form onSubmit={this.handleSubmit}>
                        <GenericForm
                            classNameLabel="col-12 col-md-3 font-weight-bold"
                            classNameInput="col-12 col-md-9"
                            labels="New Password: "
                            name="password"
                            type="password"
                            onChange={this.handleChange}
                            errorMessage={this.state.error}
                        />
                        <GenericForm
                            classNameLabel="col-12 col-md-3 font-weight-bold"
                            classNameInput="col-12 col-md-9"
                            labels="Confirm Password: "
                            name="password_confirmation"
                            type="password"
                            onChange={this.handleChange}
                            errorMessage={this.state.error}
                        />
                        <div className="form-group row">
                            <div className="col-12 text-right">
                                <button type="submit" className="btn btn-info ">
                                    {this.state.loading === true ? <FontAwesomeIcon icon="spinner" spin /> : "Change Password"}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }

}
export default (ChangePassword);