import React, { Component } from 'react';

class Contacts extends Component {

    render(){
        return (
                <div className="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4 bg-light rounded p-4 my-5">
                    <h1 className="text-center"> Developer Contact</h1>

                    <div className="row">
                        <div className="col-12 text-center">
                            <h4 className="text-danger mt-2 mb-3"> pdj milieu en industrie BV </h4>
                        </div>
                        <div className="col-12 col-sm-4 d-flex flex-row justify-content-center">
                            <div className="d-flex flex-column text-center text-sm-left">
                                <span className="text-muted mt-2"> Address </span>
                                <span> Kerkstraat 37-A </span>
                                <span> 6665CE Driel</span>
                                <span> The Netherlands </span>
                            </div> 
                        </div>
                        <div className="col-12 col-sm-4 d-flex flex-row justify-content-center">
                            <div className="d-flex flex-column text-center text-sm-left">
                                <span className="text-muted mt-2"> Phone </span>
                                <a href="tel:+31 26 4721719">+31 26 4721719</a>
                            </div>
                        </div>

                        <div className="col-12 col-sm-4 d-flex flex-row justify-content-center">
                            <div className="d-flex flex-column text-center text-sm-left">
                                <span className="text-muted mt-2"> Email </span>
                                <a href="mailto:info@pdj.nl"> info@pdj.nl </a>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default Contacts;