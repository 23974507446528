import axios from 'axios';

// process get params like post
const prepareGetUrl = (url, params) => {
    var getUrl = url;

    for (var k in params) {
        if (params.hasOwnProperty(k) && params[k] !== undefined) {
            getUrl += "/" + params[k];
        }
    }

    return getUrl
}


// get alarm data
export const getUncheckAlarms = () => {

    return dispatch => {
        dispatch({ type: 'ALARM_LOADING' });

        axios.get(prepareGetUrl('api/alarms/unchecked'))
            .then(res => {
                dispatch({ type: 'ADD_ALARM_UNCHECKED_DATA', data: res.data });
            })
            .catch(err => {
                dispatch({ type: 'ADD_ALARM_UNCHECKED_DATA_ERROR', err });
        });
    };
};