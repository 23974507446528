import React, { Component } from 'react';

class info extends Component {

    render() {
        return (
            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 bg-light rounded p-5 my-5">
                <h1 className="text-left"> Fugitive DustMonitor </h1>
                <h5> versie 2021 </h5>

                <p className="font-weight-bold my-5"> Achtergronden en handleiding </p>

                <div className="d-flex flex-column border border-dark px-3 py-1 my-5">
                    <span> Fugitive DustMonitor is ontwikkeld door </span>
                    <span className="text-danger font-weight-bold"> pdj milieu en industrie bv </span>
                    <span> kerkstraat 37a </span>
                    <span> 6665 CE Driel </span>
                    <span> www.pdj.nl </span>
                    <span> +31-(0)26-4721719 </span>
                </div>

                <div className="d-flex flex-column mt-5 pt-5">
                    <h4 className="mb-3"> 1. Waarvoor is de webapplicatie Fugitive DustMonitor? </h4>
                    <p className="text-justify"> Rondom uw bedrijf is een aantal Osiris fijnstofmeters geplaatst van het Britse bedrijf Turnkey Instruments Ltd. Hiermee bewaakt uw bedrijf de uitstoot van fijn- en grofstof vanaf uw bedrijfsterrein. Elk kwartier geven deze meters hun waarden door aan de webapplicatie Fugitive DustMonitor.  Vervolgens worden deze samen met windrichtings- en –snelheidswaarden zichtbaar gemaakt op een kaart. Hoe dat gebeurt en wat u ermee kunt, leggen we in dit document verder uit. </p>
                </div>

                <div className="d-flex flex-column mt-3">
                    <h4 className="mb-3"> 2. Inloggen </h4>
                    <p className="text-justify"> De webapplicatie is bedoeld voor gebruik op desktop systemen en laptops, en is niet gemaakt voor smartphones. Op een smartphone kunt u via de browser de website wel bereiken maar dat geeft slechts een zeer beperkt beeld. </p>
                    <p className="text-justify"> Inloggen kan via elke browser. Het adres is: <a href="https://bedrijfsnaam.stofmeten.net">https://bedrijfsnaam.stofmeten.net</a>. Een medewerker van uw bedrijf met admin-rechten kan u voorzien van de gebruikersgegevens. </p>
                    <p className="text-justify"> Via de knop “change password” rechts bovenin het scherm kunt u uw wachtwoord veranderen indien gewenst. </p>
                </div>

                <div className="d-flex flex-column mt-3">
                    <h4 className="mb-3"> 3. Home-page: De Real-Time map </h4>
                    <p className="text-justify"> Deze pagina is voor elke gebruiker van Fugitive DustMonitor zichtbaar. Wat is er te zien? </p>
                    <p className="text-justify font-weight-bold"> De grafieken </p>
                    <p className="text-justify"> De grafieken geven per kwartier de stofwaarden weer van de stofmeters die op en rondom het bedrijfsterrein staan. De locaties van de meters staan aangegeven op de kaart. De kolommen geven de gemiddelde stofconcentraties per kwartier aan in µg/m³ van de afgelopen 8 uur. Dat zijn dus 32 paaltjes. <br />De paaltjes zijn verticaal weer onderverdeeld in 4 kleuren. De onderste kleur is rood-oranje, die daarboven okergeel, dan groen en de bovenste is blauw. De kleuren staan voor de verschillende PM-waarden, zoals je onder de grafiek kunt lezen. PM is de afkorting van het Engelse woord Particulate Matter. Het betekent zoveel als zwevende of opgeloste deeltjes. In dit geval gaat het om zwevende deeltjes opgelost in lucht. </p>
                    <p className="text-justify"> Bij PM10 gaat het om alle deeltjes kleiner dan 10µm. Bij PM2.5 om alle deeltjes kleiner dan 2,5µm en PM1.0 om alle deeltjes kleiner dan 1µm. PM2.5 maakt dus deel uit van PM10, PM1.0 maakt deel uit van PM2.5 en PM10.  TSP betekent Total Suspended Particles. Hierbij gaat het om al het stof dat de stofmeetapparaten kunnen meten. De bovengrens is niet zo nauwkeurig aan te geven maar ligt rondom PM20.  </p>
                    <p className="text-justify"> Omdat de uitgelezen waarden allemaal deel van elkaar zijn hebben we er voor gekozen de 4 waarden in één kolom weer te geven. </p>
                    <p className="text-justify"> Als u een grafiek nauwkeuriger wilt bekijken kunt u er met uw muis op klikken zodat deze uitvergroot. Als u met uw muis over een paaltje beweegt worden de bijbehorende waarden zichtbaar evenals de windrichting en –snelheid in het kwartier dat u bekijkt. Met klikken op het rode kruisje rechts bovenin verdwijnt de grafiek weer. </p>
                    <p className="text-justify font-weight-bold"> Windroos en pluim </p>
                    <p className="text-justify"> Links bovenin het scherm ziet u een windroos met daarin de windrichting en –snelheid van dit moment. Voor deze grootheden is er elke minuut een nieuwe waarde. Meestal is er op de kaart ook een oker gekleurde pluim te zien. De richting van de pluim wordt bepaald door de windrichting. De lengte en de breedte ervan door de windsnelheid. </p>
                    <p className="text-justify"> De pluim is indicatief. Een lange pluim die tot aan Hoek van Holland reikt (dus met behoorlijk harde wind) betekent niet automatisch dat men in Hoek van Holland ook stofoverlast ervaart door stof van uw bedrijf. Tenslotte kan het best zo zijn dat alles op het bedrijfsterrein goed is afgedekt en de stofemissie vanaf uw bedrijf minimaal is. <nr /> Andersom kan ook waar zijn. Als de pluim niet tot in HvH reikt kunnen ze daar best wel last hebben van stofemissies van uw bedrijf. De pluim staat vooral op de kaart om in één oogopslag te kunnen zien waar een eventuele stofemissie naartoe zal waaien, en of het hard waait of niet. </p>
                    <p className="text-justify"> Om te weten of er sprake kan zijn van stofoverlast door uw bedrijf gebruiken we de stofconcentraties op de meters die in de pluim liggen en (te) hoge waarden aangeven. </p>
                    <p className="text-justify"> Met een windsnelheid van minder dan 2 m/sec is er geen pluim omdat we er dan van uit gaan dat de stofemissie buiten het terrein minimaal zal zijn. </p>
                    <p className="text-justify font-weight-bold"> Alarmen </p>
                    <p className="text-justify"> Overlast door stof van kolen op- en overslagbedrijven wordt veroorzaakt door grofstof. Grofstof is zichtbaar met het blote oog, dit in tegenstelling tot fijnstof (PM10 en kleiner). </p>
                    <p className="text-justify"> We maken bij uw bedrijf toch gebruik van de fijnstof meters omdat er geen grofstofmeters op de markt zijn die geautomatiseerd waarden in een kleine tijdsresolutie kunnen produceren. In het verleden is in onderzoek aangetoond dat er een vast verband bestaat tussen de fijnstoffractie PM10 -/- PM2.5 en het zichtbare grofstof dat een kolen op- en overslagbedrijf uitstoot. </p>
                    <p className="text-justify"> Door de super-admin kunnen er alarmen worden ingesteld. Deze alarmen worden getriggerd als de fractie PM10 -/- PM2.5 boven een bepaalde, door de super-admin ingestelde, waarde komt. Bovendien kunnen alleen de meters die lucht bemonsteren die over uw bedrijfsterrein kwam aanwaaien een alarm geven. Zo geeft een meter die te hoge waarden uitleest maar niet in de wind vanaf uw bedrijfsterrein geen alarm omdat de bron van het stof buiten het bedrijfsterrein ligt.  </p>
                    <p className="text-justify"> Als er een alarm optreedt dan komt de grafiek van de meter die het alarm veroorzaakt groot rechts in beeld. Een rode kolom geeft aan in welk kwartier de alarmwaarde werd overschreden. Er kunnen natuurlijk ook meer rode kolommen ontstaan bij een aanhoudend probleem. </p>
                    <p className="text-justify"> Daartoe gerechtigde personen kunnen na invullen van het logboek het alarm afhandelen. </p>
                </div>

                <div className="d-flex flex-column mt-3">
                    <h4 className="mb-3"> 4. Historical Map </h4>
                    <p className="text-justify"> Voor bepaalde gebruikers is er de mogelijkheid een willekeurig moment in het verleden te kiezen en de kaart van dat moment weer te geven. Vanaf dat tijdstip kun je de tijd automatisch laten lopen. Als een filmpje ziet u de opeenvolgende kaarten langs komen. </p>
                </div>

                <div className="d-flex flex-column mt-3">
                    <h4 className="mb-3"> 5. Verschillende gebruikersniveau’s </h4>
                    <p className="text-justify"> Er zijn nog drie gebruikersniveau ’s. In onderstaande tabel vindt u per niveau de bijbehorende extra rechten van dat niveau. </p>

                    <div>
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">gebruikers niveau</th>
                                    <th scope="col">Recht</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan={2}>user</td>
                                    <td>real-time map</td>
                                </tr>
                                <tr>
                                    <td>pagina met grafieken overzicht en meteo</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>

                                <tr>
                                    <td rowSpan={2}>user +</td>
                                    <td>historical map</td>
                                </tr>
                                <tr>
                                    <td>alarmen afhandelen</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>

                                <tr>
                                    <td rowSpan={2}>admin</td>
                                    <td>gebruikers administratie</td>
                                </tr>
                                <tr>
                                    <td>exporteren van gegevens</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>

                                <tr>
                                    <td rowSpan={2}>super admin (applicatiebeheerder)</td>
                                    <td>schaal van de grafieken aanpassen</td>
                                </tr>
                                <tr>
                                    <td>alarmwaarden aanpassen</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        )
    }
}

export default info;