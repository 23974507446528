import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setTimestamp, getSensorDataNow, getSensorDataInterval, resetSensorData, setDisplaySpeed,getSensorLocations, resetOldAlarm } from '../../store/actions/sensorActions';
import { toggleDatepicker } from '../../store/actions/timeControllActions';
import { getWeatherData } from '../../store/actions/weatherActions';


class DateTimeSelector extends Component {


    state = {
        date: new Date(),
        hours: '0',
        minutes: '0',
        maxSliderHours: 23,
        maxSliderMinutes: 45,
        calendarDate: new Date(),
        flagFirstRender: 1,
        markers: [{
            0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6,
            7: 7, 8: 8, 9: 9, 10: 10, 11: 11, 12: 12,
            13: 13, 14: 14, 15: 15, 16: 16, 17: 17,
            18: 18, 19: 19, 20: 20, 21: 21, 22: 22, 23: 23
        }, { 0: 0, 15: 15, 30: 30, 45: 45 }]
    }
    constructor() {
        super();
        this.dateChange = this.dateChange.bind(this);

    }
    componentDidMount = () => {
        this.changeSliderAllowedTime(new Date());
    }

    changeMinutes = () => {
        var date_now = new Date();
        var minutes = date_now.getMinutes();
        if (minutes < 15) {
            this.setState({ maxSliderMinutes: 0 })
        } else if (minutes < 30) {
            this.setState({ maxSliderMinutes: 15 })
        } else if (minutes < 45) {
            this.setState({ maxSliderMinutes: 30 })
        } else {
            this.setState({ maxSliderMinutes: 45 })
        }
    }
    changeSliderAllowedTime = (date) => {
        var today = new Date();
        if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
            var date_now = new Date();
            var hours = date_now.getHours();
            if (hours > 3) {
                this.changeMinutes();
                this.setState({ maxSliderHours: hours - 4 })
            } else {
                var last_day = new Date();
                last_day.setDate(last_day.getDate() - 1)
                this.setState({ calendarDate: last_day });
                if (this.state.flagFirstRender) {
                    this.setState({ flagFirstRender: 0 });
                    this.setState({ date: last_day })
                }

                this.changeMinutes();
                this.setState({ maxSliderHours: 23 - (3 - hours) })
            }
        } else {
            this.setState({ maxSliderMinutes: 45 })
            this.setState({ maxSliderHours: 23 })
        }
    }

    setTimestamp() {
        var stringDateTime = this.state.date.getFullYear() + '/' + this.pad2((this.state.date.getMonth() + 1)) + '/' + this.pad2(this.state.date.getDate());
        stringDateTime = stringDateTime + ' ' + this.pad2(this.state.hours) + ':' + this.pad2(this.state.minutes) + ':00'

        this.props.resetSensorData()

        const interval = {
            timestamp: Math.round(new Date(stringDateTime).getTime() / 1000), // cloud timestamp 
            timestamp_from: (Math.round(new Date(stringDateTime).getTime() / 1000) - (60 * 60 * 4)), // minus 4h
            timestamp_to: Math.round(new Date(stringDateTime).getTime() / 1000) + (60 * 60 * 4), // plus 4h
        }

        this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp, nextTimestamp: interval.timestamp + this.props.sensorUpdateInterval })

        this.props.setDisplaySpeed({ sensorDisplayChoosed: 0 }) // choose 15min

        this.props.getSensorLocations(interval.timestamp)
        this.props.getSensorDataInterval(interval)
        this.props.getSensorDataNow({ timestamp: interval.timestamp })
        this.props.getWeatherData({ timestamp: interval.timestamp })                

        this.props.toggleDatepicker()
        this.props.resetOldAlarm()
    }

    dateChange = date => {

        this.setState({ date });
        if (date !== undefined) {
            if (date.getDate() === this.state.calendarDate.getDate() && date.getMonth() === this.state.calendarDate.getMonth() && date.getFullYear() === this.state.calendarDate.getFullYear()) {
                this.changeSliderAllowedTime(new Date());
            } else {
                this.changeSliderAllowedTime(date);
            }
        }
    }

    hoursChange = hours => {
        this.setState({ hours })
    }

    minutesChange = minutes => {
        this.setState({ minutes })
    }
    pad2(number) {

        return (number < 10 ? '0' : '') + number

    }

    render() {
        const dateString = this.state.date.getFullYear() + '-' + this.pad2((this.state.date.getMonth() + 1)) + '-' + this.pad2(this.state.date.getDate());
        const timeString = this.pad2(this.state.hours) + ':' + this.pad2(this.state.minutes)

        return (
            <div className="datetimeselector p-3">
                <div className="row text-dark">
                    <div className="col-12 d-flex justify-content-between align-items-center">
                        <h3> Historical Date</h3>
                        <FontAwesomeIcon className="btn-times-close" icon="times" onClick={this.props.toggleDatepicker} title="Close"/> 
                    </div>
                    <div className="col-5">
                        <Calendar onChange={this.dateChange} maxDate={this.state.calendarDate} value={this.state.calendarDate}></Calendar>
                    </div>
                    <div className="col-7">
                        <div className="row">
                            <div className="col-12 mb-3 text-left">
                                <span> Hours </span>
                                <Slider min={0} max={this.state.maxSliderHours} defaultValue={0} marks={this.state.markers[0]} step={null} onChange={this.hoursChange} />
                            </div>
                            <div className="col-12 my-3 text-left">
                                <span> Minutes </span>
                                <Slider min={0} max={this.state.maxSliderMinutes} defaultValue={0} marks={this.state.markers[1]} step={null} onChange={this.minutesChange} />
                            </div>
                            <div className="col-12 pt-5 pb-3">
                                <h4> {dateString} {timeString} </h4>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary" onClick={() => this.setTimestamp()}> Confirm date selection </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sensorLoading: state.sensor.sensorLoading,
        sensorUpdateInterval: state.sensor.sensorUpdateInterval
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSensorDataInterval: (params) => dispatch(getSensorDataInterval(params)),
        getSensorDataNow: (params) => dispatch(getSensorDataNow(params)),
        setTimestamp: (params) => dispatch(setTimestamp(params)),
        resetSensorData: () => dispatch(resetSensorData()),
        setDisplaySpeed: (params) => dispatch(setDisplaySpeed(params)),
        toggleDatepicker : () => dispatch( toggleDatepicker() ),
        getSensorLocations: (params) =>  dispatch( getSensorLocations(params) ),
        getWeatherData: (params) => dispatch(getWeatherData(params)),
        resetOldAlarm : () => dispatch( resetOldAlarm() ),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeSelector);