import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTimestamp, getSensorDataNow, getSensorDataInterval, resetSensorData, setDisplaySpeed } from '../../store/actions/sensorActions';
import { getWeatherData } from '../../store/actions/weatherActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class SpeedControll extends Component {

    goBackTimestamp(amount_back_hours){

        var interval = {
            timestamp: this.props.sensorCloudTimestamp - (60*60*amount_back_hours),
            timestamp_from: this.props.sensorTimestamp  - (60*60*8), // minus 8h
            timestamp_to: this.props.sensorTimestamp
        }

        if( this.props.sensorTimestamp - (this.props.sensorCloudTimestamp - (60*60*amount_back_hours)) > (60*60*4)){
            interval = {
                timestamp: this.props.sensorCloudTimestamp - (60*60*amount_back_hours),
                timestamp_from: (this.props.sensorCloudTimestamp  - (60*60*4)), // minus 4h
                timestamp_to: (this.props.sensorCloudTimestamp  + (60*60*4)), // plus 4h
            }
        }

        this.props.setTimestamp({ timestamp: interval.timestamp_to, cloudTimestamp: interval.timestamp, nextTimestamp: interval.timestamp + this.props.sensorUpdateInterval })

        this.props.setDisplaySpeed({ sensorDisplayChoosed: 0}) // choose 15min

        this.props.getSensorDataInterval(interval)
        this.props.getSensorDataNow({ timestamp: interval.timestamp })
        this.props.getWeatherData({ timestamp: interval.timestamp })
    }                            


    setSpeed = (op, value) => {

        switch(op) {
            case "=": {
                this.props.setDisplaySpeed({ sensorDisplayChoosed: value})
                break
            }
            case "+": {
                this.props.setDisplaySpeed({ sensorDisplayChoosed: this.props.sensorDisplayChoosed + value})
                break
            }
            case "-": {
                this.props.setDisplaySpeed({ sensorDisplayChoosed: this.props.sensorDisplayChoosed - value})
                break
            }
            default:
                break
        }
    }

    render(){
        
        return (
            <div className="row">
                <div className="col-12">
                    <button className="btn btn-info" onClick={()=> this.goBackTimestamp(1) }> <FontAwesomeIcon icon="backward" /> </button>
                   
                    <button className="btn btn-info mx-1" onClick={()=> this.setSpeed("=",0)}> <FontAwesomeIcon icon="pause" /> </button>

                    <b> { this.props.sensorDisplaySpeed[this.props.sensorDisplayChoosed].label } </b>

                    <button className="btn btn-info mx-1" onClick={()=> this.setSpeed("=",2)}> <FontAwesomeIcon icon="play" /> </button>
                    {/* { 
                        this.props.sensorTimestamp + this.props.sensorDisplaySpeed[this.props.sensorDisplayChoosed].speed < Math.round(new Date().getTime()/1000) ?
                        <button className="btn btn-info" onClick={()=> this.setSpeed("+",1)} disabled={this.props.sensorDisplayChoosed >= 3} > <FontAwesomeIcon icon="forward" /> </button>
                        : null
                    }
                         */}
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        sensorDisplayChoosed: state.sensor.sensorDisplayChoosed,
        sensorDisplaySpeed: state.sensor.sensorDisplaySpeed,
        sensorTimestamp: state.sensor.sensorTimestamp,
        sensorCloudTimestamp: state.sensor.sensorCloudTimestamp,
        sensorUpdateInterval: state.sensor.sensorUpdateInterval
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSensorDataInterval : (params) => dispatch( getSensorDataInterval(params) ),
        getSensorDataNow : (params) => dispatch( getSensorDataNow(params) ),
        setTimestamp: (params) => dispatch(setTimestamp(params)),
        resetSensorData: () => dispatch( resetSensorData() ),
        setDisplaySpeed : (params) => dispatch( setDisplaySpeed(params) ),
        getWeatherData: (params) => dispatch(getWeatherData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeedControll)