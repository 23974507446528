import React, { Component } from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux';
import { logout } from '../../store/actions/authActions';
import { getSensorDataInterval, getSensorDataNow, setTimestamp, resetSensorData } from '../../store/actions/sensorActions';
import { getWeatherData } from '../../store/actions/weatherActions';


class Navbar extends Component {


    logoutWithRedirect = () => {
        this.props.logout()
        this.props.resetSensorData()


        const interval = {
            timestamp_from: (Math.round(new Date().getTime()/1000) - (60*60*8)), // minus 8h
            timestamp_to: Math.round(new Date().getTime()/1000),
        }

        this.props.setTimestamp({ timestamp: interval.timestamp_to, nextTimestamp: interval.timestamp_to + this.props.sensorUpdateInterval })

        this.props.getSensorDataInterval(interval)
        this.props.getSensorDataNow({ timestamp: interval.timestamp_to })
        this.props.getWeatherData({ timestamp: interval.timestamp_to })
    }

    adminOptions = () => {
        if(this.props.user !== null && this.props.user.role !== null && this.props.user.role !== undefined && (this.props.user.role.name === 'SuperAdmin' || this.props.user.role.name === 'Admin')){
            return (
                <>
                <li className="nav-item">
                    <NavLink to='/admin' className="nav-link" exact={false}> Admin </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to='/export' className="nav-link" activeClassName='active' exact={true}> Export </NavLink>
                </li>
                </>
            );
        }
        
        return null;
    }

    highPrevilegeOptions = () => {
        if(this.props.user !== null && this.props.user.role !== null && this.props.user.role !== undefined){

            if (this.props.user.role.name === 'SuperAdmin' || this.props.user.role.name === 'Admin' || this.props.user.role.name === 'User+'){
                return (
                    <li className="nav-item">
                        <NavLink to='/alarms' className={this.props.alarmUncheckedData && this.props.alarmUncheckedData.length >0 ? "nav-link btn-glowing rounded": "nav-link"} exact={false}> Alarms <small>{this.props.alarmUncheckedData && this.props.alarmUncheckedData.length >0 ? "("+this.props.alarmUncheckedData.length+")" : null}</small> </NavLink>
                    </li>
                );
            }
            else {
                return (
                    <li className="nav-item">
                        <span className={this.props.alarmUncheckedData && this.props.alarmUncheckedData.length >0 ? "nav-link btn-glowing rounded": "nav-link"} exact={false}> Alarms <small>{this.props.alarmUncheckedData && this.props.alarmUncheckedData.length >0 ? "("+this.props.alarmUncheckedData.length+")" : null}</small> </span>
                    </li>
                )
            }
        }
        
        return null;
    }

    // create menu right
    menuRight = () => {
        const { location } = this.props;
        const rightClass = location.pathname==='/' || location.pathname==='/charts' ? 'navbar-nav' : 'navbar-nav ml-auto'

        if(this.props.loggedIn === true){

            return (
                <ul className={rightClass}>
                    <li className="nav-item">
                        <NavLink to='/contacts' className="nav-link" exact={true}> Contacts </NavLink>
                    </li>
                    
                    { this.adminOptions() }

                    <li className="nav-item">
                        <NavLink to='/user/changepassword' className="nav-link" activeClassName='active' exact={true}> Change Password </NavLink>
                    </li>
                    <li className="nav-item">
                        <span className="nav-link"  onClick={ this.logoutWithRedirect }> Logout </span>
                    </li>
                </ul>
            );
        }
        else {
            return (
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <NavLink to='/contacts' className="nav-link" exact={true}> Contacts </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to='/auth/login' className="nav-link" exact={true}> Login </NavLink>
                    </li>
                </ul>
            );
        }
    }

    // create menu left
    menuLeft = () => {
        if(this.props.loggedIn === true){
            return (
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <NavLink to='/' className="nav-link" exact={true}> Maps </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to='/charts' className="nav-link" exact={true}> Charts </NavLink>
                    </li>
                    
                    { this.highPrevilegeOptions() }

                    <li className="nav-item">
                        <NavLink to='/info' className="nav-link" exact={true}> Info </NavLink>
                    </li>
                </ul>
            );
        }
        
        return null;
    }

    render() {
        const { location } = this.props;

        let buttonName='Real-Time Map'

        if(this.props.timeCloud >= Math.floor(Date.now() / 1000)-60){
            buttonName='Real-Time Map';
        }else{
            buttonName='Historical Map';
        }
      
        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <span className="h4 mr-3">Fugitive Dust Monitor</span>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    { this.menuLeft() } 
                    { location.pathname==='/' || location.pathname==='/charts' ? <span className="mx-auto h4"><u>{ buttonName }</u></span> : null}                 
                    { this.menuRight() }                      
                </div>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.auth.loggedIn,
        user: state.auth.user,
        sensorUpdateInterval: state.sensor.sensorUpdateInterval,
        timeCloud: state.sensor.sensorCloudTimestamp,
        alarmUncheckedData: state.alarm.alarmUncheckedData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSensorDataInterval : (params) => dispatch( getSensorDataInterval(params) ),
        getSensorDataNow : (params) => dispatch( getSensorDataNow(params) ),
        setTimestamp: (params) => dispatch(setTimestamp(params)),
        resetSensorData: () => dispatch( resetSensorData() ),
        logout : () => dispatch( logout() ),
        getWeatherData: (params) => dispatch(getWeatherData(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));