const initState = {
    weatherLoading: false,
    weatherError: null,
    weatherData: null
}

const weatherReducer = (state = initState, action) => {
    switch(action.type){
        case 'ADD_WEATHER_DATA':
            return {
                ...state,
                weatherData: action.data,
                weatherLoading: false,
            }
        case 'ADD_WEATHER_DATA_ERROR':
            return {
                ...state,
                weatherError: (action.err.response !== undefined && action.err.response.data !== undefined ? action.err.response.data : "Internal Error"),
                weatherData: null,
                weatherLoading: false,
            }
        case 'RESET_WEATHER_DATA':
            return {
                weatherLoading: false,
                weatherError: null,
                weatherData: null
            }
        case 'WEATHER_LOADING':
            return {
                ...state,
                weatherLoading: true,
            }
        default: 
            return state;
    }
}

export default weatherReducer